<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-select v-model="driver_.employee_id" size="medium" value-key="uid">
        <template #prefix>Funcionário:</template>
        <el-option
          v-for="item in Employees"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
      <el-select v-model="driver_.car_id" size="medium" value-key="uid">
        <template #prefix>Veículo:</template>
        <el-option
          v-for="item in Cars"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="space-between">
        <h5>Pode efetuar entrega?</h5>
        <el-switch
          v-model="driver_.can_deliver"
          active-text="Sim"
          :active-value="1"
          :inactive-value="0"
          inactive-text="Não"
          active-color="#409EFF"
          inactive-color="#409EFF"
        ></el-switch>
      </el-row>

      <el-select v-model="driver_.user_id" size="medium" value-key="uid">
        <template #prefix>Vincular usuário:</template>
        <el-option
          v-for="item in Users"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import UserService from "../../services/users";
import DriverService from "../../services/drivers";
import CarService from "../../services/cars";
import EmployeeService from "../../services/employees";
import { notifySuccess, notifyError } from "../../utils/notifiers";
export default {
  props: ["driver", "showModal"],
  //components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      driver_: {},
      cars: [],
      users: [],
      employees: null,
      isLoadingSave: false,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  watch: {
    driver(v) {
      this.driver_ = v || {};
      this.driver_.employee_id = v?.employee?.uid;
      this.driver_.car_id = v?.car?.uid;
      this.driver_.user_id = v?.user?.uid;
      if (v.user) this.users = [v?.user];
    },
    showModal(v) {
      if (v) {
        this.fetchEmployees();
        this.fetchUsers();
        this.fetchCars();
      }
    },
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar motorista" : "Cadastrar motorista";
    },
    isNew() {
      return !this.driver;
    },
    Users() {
      return this.users;
    },
    Cars() {
      return this.cars || [];
    },
    Employees() {
      return (
        this.employees?.filter(
          (e) => e.uid === this.driver_?.employee?.uid || !e.driver
        ) || []
      );
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateDriver() : this.createDriver();
    },
    async fetchUsers() {
      const { users } = await UserService().index();

      if (users) this.users = users;
    },
    async createDriver() {
      const { driver, error } = await DriverService().create(this.driver_);

      if (driver) {
        this.$emit("should-update");
        this.shouldShow = false;
        this.driver_ = {};
        notifySuccess(driver.message);
      } else notifyError(error.message);

      this.isLoadingSave = false;
    },
    async fetchEmployees() {
      const { employees } = await EmployeeService().index();

      if (employees) this.employees = employees;
    },
    async fetchCars() {
      const { cars } = await CarService().index();

      if (cars) this.cars = cars;
    },
    async updateDriver() {
      const { driver, error } = await DriverService(this.driver_.uid).update(
        this.driver_
      );

      if (driver) {
        this.$emit("should-update");
        this.isLoadingSave = false;
        this.shouldShow = false;
        notifySuccess(driver.message);
      } else notifyError(error.message);
    },
  },
  name: "DriverModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>