<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Motoristas cadastrados</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openDriverModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="drivers"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="employee.name" label="nome"> </el-table-column>
      <el-table-column prop="car.name" label="veículo atual"> </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openDriverModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteDriver(cat.row)"
                title="
              Remover motorista?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <driver-modal
      :showModal="showDriverModal"
      :driver="driver"
      @close-modal="showDriverModal = false"
      @should-update="fetchDrivers"
    ></driver-modal>
  </el-card>
</template>

<script>
import DriverModal from "./modals/DriverModal.vue";

export default {
  name: "DriversPage",
  components: { DriverModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    drivers: null,
    driver: null,
    firm: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    showDriverModal: false,
    showVehicleObsModal: false,
  }),
  mounted() {
    this.fetchDrivers();
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    deleteDriver(d) {
      fetch(`${this.$store.state.apiUrl}drivers/${d.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchDrivers();
          this.$notify({
            title: "Motorista removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o produto",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    openDriverModal(e) {
      (this.driver = e), (this.showDriverModal = true);
    },
    fetchDrivers() {
      fetch(`${this.$store.state.apiUrl}drivers`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.drivers = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>